<template>
  <SdPage
    id="tour-page"
    :class="$viewport.lt.md ? 'mobile' : ''"
  >
    <Portal to="container-top">
      <SdBanner
        v-if="timeLeftRef && scheduleRef.tour_status === tourConstants.STATUS_SCHEDULED_TAKEN_OVER"
        bg-class="bg-purple"
      >
        <div class="text-center">
          <span class="font-weight-strong">
            {{ uiFlags.tourType }} was assigned.
          </span>
          <br v-if="$viewport.lt.md">
          Time until {{ uiFlags.tourType }} {{ timeLeftRef }}
        </div>
      </SdBanner>
    </Portal>
    <TourReassignDialog
      v-if="canBeReassigned(scheduleRef)"
      :schedule="scheduleRef"
      :visible.sync="uiFlags.reassignDialogVisible"
      @close="reassignDialogClosed"
    />
    <TourRescheduleDialog
      v-if="canBeRescheduled(scheduleRef)"
      :schedule="scheduleRef"
      :visible.sync="uiFlags.rescheduleDialogVisible"
      @close="rescheduleDialogClosed"
    />
    <TourTakeOverDialog
      v-if="takeOverIsPossibleRef"
      :self-showing="scheduleRef.is_self_showing"
      :schedule-id="+scheduleId"
      :schedule="scheduleRef"
      :is-video="scheduleRef.is_video"
      :visible.sync="uiFlags.takeOverDialogVisible"
      @close="takeOverDialogClosed"
    />
    <UnitAddInquiryDialog
      v-if="uiFlags.isAddInquiryDialogVisible"
      :unit="unitRef"
      :lead="leadRef"
      :is-disabled-lead-edit="true"
      @close="uiFlags.isAddInquiryDialogVisible = false"
    />
    <TourCancelDialog
      v-if="canBeCancelled(scheduleRef)"
      :schedule="scheduleRef"
      :visible.sync="uiFlags.cancelDialogVisible"
      @close="cancelDialogClosed"
    />
    <SdPageHeader
      :class="$viewport.lt.md ? 'white-app-layout pl-3 pr-3 pt-3 pb-1' : 'mb-4'"
      :title="`${uiFlags.tourType} #${scheduleId}`"
      :back-route="{ name: 'app.tours' }"
    >
      <template
        v-if="scheduleRef.is_video"
        v-slot:title-tag
      >
        <LiveVideoTourTag />
      </template>
      <ElTooltip
        :disabled="takeOverIsPossibleRef || !!scheduleRef.is_taken_over"
        popper-class="action-popper-class"
        placement="top"
      >
        <template slot="content">
          <p class="text-center">
            You can only reassign a {{ uiFlags.tourType }} up to {{ takeoverThresholdMinutes }} minutes in advance
          </p>
        </template>
        <span>
          <ElButton
            :disabled="!takeOverIsPossibleRef || !!scheduleRef.is_taken_over"
            type="purple"
            size="small"
            @click="uiFlags.takeOverDialogVisible = true"
          >
            Reassign to My Team
          </ElButton>
        </span>
      </ElTooltip>
      <ElButton
        class="ml-2"
        size="small"
        :disabled="disabledScheduleAgainButton()"
        @click="uiFlags.isAddInquiryDialogVisible = true"
      >
        Schedule Again
      </ElButton>
    </SdPageHeader>
    <SdLoadingLayer :init="init">
      <template #loaded>
        <div class="row tour-page-row mt-0">
          <SdConditionallyBumpChildren :bump-if="$viewport.lt.md">
            <el-tabs
              v-model="activeTabRef"
              :stretch="true"
            >
              <SdConditionallyBumpChildren :bump-if="$viewport.lt.md">
                <el-tab-pane
                  v-touch:swipe="swipeHandler"
                  label="Tour Details"
                >
                  <div
                    class="col-lg-4"
                    :class="$viewport.lt.md ? 'p-0' : ''"
                  >
                    <h2
                      v-if="$viewport.gt.md"
                      class="text-gray-darker font-21 font-weight-bolder"
                    >
                      {{ uiFlags.tourType }} Details
                    </h2>
                    <TourDetails
                      :schedule="scheduleRef"
                      @show-reassign-dialog="uiFlags.reassignDialogVisible = true"
                      @show-reschedule-dialog="uiFlags.rescheduleDialogVisible = true"
                      @show-cancel-dialog="uiFlags.cancelDialogVisible = true"
                    />
                  </div>
                </el-tab-pane>
              </SdConditionallyBumpChildren>
              <SdConditionallyBumpChildren :bump-if="$viewport.lt.md">
                <el-tab-pane
                  v-if="showTourReviewSection"
                  v-touch:swipe="swipeHandler"
                  label="Tour Notes"
                >
                  <div
                    class="col-lg-8"
                    :class="$viewport.lt.md ? 'p-0' : ''"
                  >
                    <template v-if="showTourReviewSection">
                      <h2
                        v-if="$viewport.gt.md"
                        class="text-gray-darker font-21 font-weight-bolder"
                      >
                        {{ uiFlags.tourType }} Review
                      </h2>
                      <TourReview
                        v-if="scheduleRef.leads.length && scheduleRef.survey_result"
                        :tour-type="uiFlags.tourType"
                        :prospects="scheduleRef.leads.filter(lead => lead.survey_result)"
                        :survey-results="scheduleRef.survey_result.results"
                      >
                        <SdStatusBadge :type="scheduleRef.tour_status" />
                      </TourReview>
                      <TourReviewSubmissionForm
                        v-else-if="scheduleRef.is_taken_over && scheduleRef.tour_status && scheduleRef.tour_status.includes('cancelled') === false"
                        :tour-type="uiFlags.tourType"
                        :schedule="scheduleRef"
                        @submitted="reviewSubmitted"
                      />
                      <TourReview
                        v-else-if="scheduleRef.timed_out_at"
                        :tour-type="uiFlags.tourType"
                        :prospects="[]"
                        :survey-results="[{ answer: 'The prospect did not finalize this self-showing tour' }]"
                      >
                        <SdStatusBadge type="timed_out" />
                      </TourReview>
                      <div
                        v-else
                        class="tour-notes-empty-state"
                      >
                        <img :src="NoteImage">
                        <p class="mt-5 font-17 text-gray-dark font-weight-normal">
                          {{ scheduleRef.tour_status === 'completed' ? `Waiting for ${ uiFlags.tourType } notes` : `notes will be available once ${uiFlags.tourType } is finished` }}
                        </p>
                      </div>
                    </template>
                    <TourActivities
                      v-if="$viewport.gt.md"
                      :tour-type="uiFlags.tourType"
                      :schedule="scheduleRef"
                    />
                  </div>
                </el-tab-pane>
              </SdConditionallyBumpChildren>
              <div
                v-if="!showTourReviewSection && $viewport.gt.md"
                class="col-lg-8"
              >
                <TourActivities
                  :tour-type="uiFlags.tourType"
                  :schedule="scheduleRef"
                />
              </div>
              <SdConditionallyBumpChildren :bump-if="$viewport.lt.md">
                <el-tab-pane
                  v-touch:swipe="swipeHandler"
                  :label="`${uiFlags.tourType} events`"
                >
                  <TourActivities
                    v-if="$viewport.lt.md"
                    :tour-type="uiFlags.tourType"
                    :schedule="scheduleRef"
                  />
                </el-tab-pane>
              </SdConditionallyBumpChildren>
            </el-tabs>
          </SdConditionallyBumpChildren>
        </div>
      </template>
    </SdLoadingLayer>
  </SdPage>
</template>

<script>

import { computed, reactive, ref } from '@vue/composition-api';
import NoteImage from '@/assets/note.svg';
import { Portal } from 'portal-vue';

import get from 'lodash.get';
import {
  canBeCancelled, canBeRescheduled, getBadgeFormatStatus, canBeReassigned,
} from '../../../utils/ScheduleUtil';
import TourDetails from '../../../components/tour/TourDetails';
import TourReview from '../../../components/tour/TourReview';
import UnitAddInquiryDialog from '../../../components/unit/UnitAddInquiryDialog';
import TourTakeOverDialog from '../../../components/tour/TourTakeOverDialog';
import TourReassignDialog from '../../../components/tour/TourReassignDialog';
import TourCancelDialog from '../../../components/tour/TourCancelDialog';
import TourReviewSubmissionForm from '../../../components/tour/TourReviewSubmissionForm';
import TourActivities from '../../../components/tour/TourActivities';
import tourConstants from '../../../constants/Tour';
import TourRescheduleDialog from '../../../components/tour/TourRescheduleDialog';
import LiveVideoTourTag from '../../../components/tour/LiveVideoTourTag';
import { getSettingValueByKey } from '../../../utils/SettingUtil';

export default {
  name: 'Tour',
  components: {
    LiveVideoTourTag,
    TourRescheduleDialog,
    TourTakeOverDialog,
    TourReassignDialog,
    TourCancelDialog,
    TourReview,
    TourActivities,
    TourDetails,
    TourReviewSubmissionForm,
    UnitAddInquiryDialog,
    Portal,
  },
  setup(props, context) {
    const scheduleId = context.root.$route.params.id;
    const activeTabRef = ref('0');
    const uiFlags = reactive({
      takeOverDialogVisible: false,
      reassignDialogVisible: false,
      rescheduleDialogVisible: false,
      cancelDialogVisible: false,
      isAddInquiryDialogVisible: false,
      tourType: '',
    });
    const scheduleRef = ref({});
    const unitRef = ref({});
    const leadRef = ref({});
    const isMultipleParty = ref({});
    const timeLeftRef = ref(null);
    const showTourReviewSection = computed(() => ['Successful', 'Upcoming', 'Upcoming - Assigned', 'Prospect no-show', 'Access Issue'].includes(getBadgeFormatStatus(scheduleRef.value.tour_status).label));
    const takeOverIsPossibleRef = ref(false);
    const takeoverThresholdMinutes = getSettingValueByKey('threshold_for_takeover');

    return {
      tourConstants,
      activeTabRef,
      timeLeftRef,
      swipeHandler,
      takeOverIsPossibleRef,
      uiFlags,
      NoteImage,
      TourDetails,
      scheduleId,
      scheduleRef,
      unitRef,
      leadRef,
      getBadgeFormatStatus,
      canBeCancelled,
      canBeRescheduled,
      showTourReviewSection,
      init,
      canBeReassigned,
      takeOverDialogClosed,
      reassignDialogClosed,
      rescheduleDialogClosed,
      cancelDialogClosed,
      reviewSubmitted,
      takeoverThresholdMinutes,
      disabledScheduleAgainButton,
    };

    function initTimer() {
      const end = context.root.$momentUtil(scheduleRef.value.tour_at, null);
      const calcTimeLeft = () => {
        const timeLeftInSeconds = context.root.$momentUtil(end.diff(context.root.$momentUtil(null, null), 'seconds'), null, 'x');
        const hours = parseInt(timeLeftInSeconds / 3600);
        const minutes = parseInt(timeLeftInSeconds / 60) % 60;
        const seconds = timeLeftInSeconds % 60;

        timeLeftRef.value = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
      };
      calcTimeLeft();
      setInterval(calcTimeLeft, 1000);
    }

    function swipeHandler(direction) {
      if (direction === 'left') {
        activeTabRef.value = activeTabRef.value !== '2' ? (+activeTabRef.value + 1).toString() : '2';
      } else if (direction === 'right') {
        activeTabRef.value = activeTabRef.value !== '0' ? (+activeTabRef.value - 1).toString() : '0';
      }
    }

    async function init() {
      scheduleRef.value = await context.root.$store.dispatch('Business/getTour', scheduleId);
      const schedule = scheduleRef.value;
      uiFlags.tourType = schedule.is_open_house ? 'Open House' : 'Tour';
      if (!schedule.is_open_house) {
        unitRef.value = {
          id: schedule.unit_id,
          name: schedule.unit_name,
          property: {
            timezone: schedule.property_timezone,
            short_address: schedule.property_address,
          },
        };
        leadRef.value = get(schedule, 'leads.[0]');
        isMultipleParty.value = !!(!schedule.is_open_house && get(schedule, 'leads', []).length > 1);
      }
      document.title += ` | ${schedule.property_address}${schedule.unit_name ? ` - ${schedule.unit_name}` : ''}`;
      takeOverIsPossibleRef.value = scheduleRef.value.can_be_taken_over;
      if (!scheduleRef.value.can_be_taken_over) {
        initTimer();
      }
    }

    function takeOverDialogClosed(takeOverData) {
      if (takeOverData) {
        init();
      }
      uiFlags.takeOverDialogVisible = false;
    }

    function reassignDialogClosed(status) {
      if (status === 'success') {
        init();
      }
      uiFlags.reassignDialogVisible = false;
    }

    function rescheduleDialogClosed(status) {
      if (status === 'success') {
        init();
      }
      uiFlags.rescheduleDialogVisible = false;
    }

    function cancelDialogClosed(status) {
      if (status === 'success') {
        init();
      }
      uiFlags.cancelDialogVisible = false;
    }

    function reviewSubmitted() {
      init();
    }

    function disabledScheduleAgainButton() {
      return !!(scheduleRef.value?.tour_status !== tourConstants.STATUS_COMPLETED && scheduleRef.value.tour_status && !scheduleRef.value.tour_status.includes('cancelled') && scheduleRef.value?.tour_status !== tourConstants.STATUS_TECHNICAL_ISSUE && scheduleRef.value?.tour_status !== tourConstants.STATUS_NO_SHOW) || !!(uiFlags.tourType === 'Open House') || !!isMultipleParty.value;
    }
  },
};
</script>
<style lang="scss">
#tour-page.mobile {
  padding: 0;

  .el-tabs__header {
    margin: 0;
    box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.11);
    z-index: 1;
    background: white;
  }

  .el-tabs__nav-wrap.is-scrollable {
    padding: 0;
  }

  .el-table {
    font-size: $font-size-sm;

    .cell {
      padding: 0 2rem;

      span {
        white-space: nowrap;
      }
    }

    th,
    td {
      padding: 10px 0;
    }
  }

  @media (max-width: 540px) {
    .tour-page-row {
      margin: 0;
    }
  }
}

.sd-banner-wrapper {
  margin-bottom: 2.5rem;
}

.tour-notes-empty-state {
  background: $--color-white;
  min-height: 20rem;
  border-radius: $app-border-radius;
  flex-direction: column;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  @media (max-width: 540px) {
    border: none;
    border-radius: unset;
  }

  img {
    width: 6rem;
  }
}
</style>
